.about-section {
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 50px auto;
  padding: 20px;
  box-sizing: border-box;
  /* background: #100c08; */
  overflow-x: hidden;
}

.about-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 32px;
  align-items: center;
}

@media (min-width: 768px) {
  .about-container {
    flex-direction: row;
    gap: 16px;
  }
}

.about-content {
  flex: 1;
  width: 100%;
  max-width: 66.6%; /* Matches md:w-2/3 */
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .about-content {
    max-width: 50%; /* Matches lg:w-1/2 */
  }
}

.about-title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.2;
  color: #f5f5f5;
  margin-bottom: 16px;
}

.about-description,
.about-content p {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  text-align: justify;
  color: #f5f5f5;
  margin-bottom: 16px;
}

.content-item {
  margin-bottom: 16px;
}

.content-item h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  color: #f5f5f5;
  margin-bottom: 8px;
}

.decorative-image {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.decorative-image img {
  width: 100%;
  max-width: 80%; /* Matches md:w-4/5 */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (min-width: 1024px) {
  .decorative-image img {
    max-width: 60%; /* Matches lg:w-3/5 */
  }
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .about-title {
    font-size: 2rem;
  }

  .about-description,
  .about-content {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 16px;
    margin-top: 30px;
  }

  .about-title {
    font-size: 1.75rem;
    margin-bottom: 30px;
  }

  .about-description {
    font-size: 0.875rem;
    margin-bottom: 40px;
  }

  .content-item h3 {
    font-size: 1.25rem;
  }

  .content-item p {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .about-section {
    padding: 12px;
    margin-top: 20px;
  }

  .about-title {
    font-size: 1.5rem;
    text-align: center;
  }

  .about-description {
    font-size: 0.75rem;
  }

  .content {
    gap: 16px;
  }

  .content-item h3 {
    font-size: 1rem;
  }

  .content-item p {
    font-size: 0.75rem;
  }
}
