/* Hero Section */
.hero-section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: url('4.png') no-repeat center center;
    background-size: cover;
    background-attachment: fixed; /* Prevent background duplication */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap: clamp(1.5rem, 3vw, 2.5rem);
    box-sizing: border-box;
    overflow: hidden;
}


.hero-title {
    width: min(90%, 857px);
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(2.5rem, 5vw, 4rem);
    line-height: 1.4;
    text-align: center;
    color: #F5F5F5;
}

.hero-description {
    width: min(90%, 1205px);
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: clamp(1rem, 2vw, 1.4rem);
    line-height: 1.4;
    text-align: center;
    color: #F5F5F5;
    margin: 1rem 0;
}

.hero-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: clamp(1rem, 3vw, 6rem);
    width: 100%;
    max-width: 507px;
    flex-wrap: wrap;
    margin: 1rem 0;
}

.hero-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: clamp(0.8rem, 2vw, 1.1rem) clamp(1.5rem, 3vw, 2.2rem);
    background: #FFFFFF;
    border-radius: 1rem;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(0.9rem, 1.5vw, 1.1rem);
    line-height: 1.4;
    color: #100C08;
    white-space: nowrap;
    transition: all 0.3s ease;
}

.hero-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Mouse Icon */
.mouse-icon {
    position: absolute;
    bottom: clamp(50px, 8vh, 150px); /* Scales based on screen height */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: clamp(70px, 10vw, 120px); /* Dynamic width */
    height: clamp(90px, 12vw, 140px); /* Dynamic height */
    gap: clamp(0.5rem, 2vw, 1rem); /* Adjust mouse icon spacing */
    transition: all 0.3s ease; /* Smooth transition for responsive scaling */
}

/* Mouse Outline */
.mouse-icon .mouse {
    position: relative;
    width: clamp(25px, 5vw, 40px); /* Scale width */
    height: clamp(45px, 7vw, 60px); /* Scale height */
    margin: auto;
    border: 2px solid white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    transform: translateY(-105%);
    justify-content: center;
}

/* Scrolling Wheel */
.mouse-icon .scroll-wheel {
    position: absolute;
    top: 10px;
    left: 50%;
    width: clamp(5px, 1vw, 6px); /* Scale based on viewport */
    height: clamp(10px, 1.5vw, 12px); /* Scale based on viewport */
    background-color: white;
    border-radius: 2px;
    animation: scroll-wheel-animation 2s ease-in-out infinite;
    transform: translateX(-50%);
}

/* Scroll Down Text */
.mouse-icon .scroll-down {
    margin-top: 50px;
    font-family: 'Poppins', sans-serif;
    font-size: clamp(10px, 2vw, 14px); /* Scale based on viewport */
    color: #FFFFFF;
    text-align: center;
}

.scroll-wheel {
    animation: scroll-wheel-animation 2s ease-in-out infinite;
  }
  
  /* Scroll Animation */
  @keyframes scroll-wheel-animation {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    50% {
      transform: translateY(5px);
      opacity: 0.5;
    }
    100% {
      transform: translateY(10px);
      opacity: 0;
    }
  }
  

/* Scroll Animation */
@keyframes scroll-wheel-animation {
    0% {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
    50% {
        transform: translateX(-50%) translateY(10px);
        opacity: 0.5;
    }
    100% {
        transform: translateX(-50%) translateY(20px);
        opacity: 0;
    }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .hero-buttons {
        flex-direction: column;
        gap: 1rem;
    }

    .hero-button {
        width: 100%;
        max-width: 300px;
    }

    /* Adjust the mouse icon positioning and size */
    .mouse-icon {
        bottom: clamp(40px, 10vh, 120px); /* Adjust bottom spacing for smaller screens */
        width: clamp(50px, 8vw, 100px); /* Scale down width */
        height: clamp(70px, 10vw, 120px); /* Scale down height */
        gap: 0.8rem; /* Adjust spacing between mouse elements */
    }

    .mouse-icon .mouse {
        width: clamp(20px, 4vw, 35px); /* Adjust width */
        height: clamp(40px, 6vw, 55px); /* Adjust height */
    }

    .mouse-icon .scroll-wheel {
        width: clamp(4px, 0.8vw, 5px); /* Adjust scroll wheel width */
        height: clamp(8px, 1.2vw, 10px); /* Adjust scroll wheel height */
    }

    .mouse-icon .scroll-down {
        font-size: clamp(8px, 1.5vw, 12px); /* Adjust font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .hero-section {
        padding: 1rem;
        text-align: center;
    }

    .hero-title {
        width: 100%;
    }

    .hero-description {
        width: 100%;
    }

    /* Further adjustments for very small screens */
    .mouse-icon {
        bottom: 10%; /* Adjust bottom to be proportional */
        width: clamp(40px, 6vw, 80px); /* Scale down for mobile */
        height: clamp(60px, 8vw, 100px); /* Scale down for mobile */
    }

    .mouse-icon .mouse {
        width: clamp(28px, 11vw, 30px);
        height: clamp(50px, -49vw, 45px); /* Further scale down */
    }

    .mouse-icon .scroll-wheel {
        width: clamp(3px, 0.5vw, 4px); /* Further reduce scroll wheel size */
        height: clamp(6px, 1vw, 8px); /* Further reduce scroll wheel height */
    }

    .mouse-icon .scroll-down {
        font-size: clamp(6px, 1vw, 10px); /* Smaller font for mobile */
    }
}
