.card {
  width: 100%;
  max-width: 1280px;
  height: 78.69px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 33.7237px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.card:hover {
  background: rgba(0, 0, 0, 0.7);
}
  
  .card-body {
    display: flex;
    align-items: center;
    width: 94%;
    justify-content: space-between;
  }
  
  .logo {
    display: block;
    width: 70px;
    height: 70px;
    background: url('favicon.ico') no-repeat center center;
    background-size: cover;
    border-radius: 50%;
    margin-right: 1rem;
    margin-left: -13rem;
  }
  
  .nav-links {
    display: flex;
    gap: 2rem;
  }
  
  .nav-link {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.2s ease;
  }
  
  .nav-link:hover {
    color: #ffcc00;
    transform: scale(1.1);
  }
  
  .nav-link.active {
    font-weight: 700;
    color: yellow;
  }
  
  
  .contact-button {
    padding: 1rem 2rem;
    width:150px;
    height: 60px; /* Increase padding for larger button */
    background: #ffffff;
    border-radius: 30px;
    border: none;
    font-family: 'Inter', sans-serif;
    font-size: 24px; /* Increase font size */
    color: #100C08;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-right: -5rem;
  }
  
  .contact-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(255, 204, 0, 0.6);
  }
  
  /* Mobile Styles */
 /* Mobile Styles */
/* Mobile and Tablet Styles */
@media (max-width: 1024px) {
  .card {
    flex-direction: column;  /* Stack elements vertically */
    height: auto;
    padding: 2rem;
    text-align: center;
    border-radius: 16px;  /* Slightly smaller rounded corners for tablets */
    left: -9rem;
  }

  .logo {
    margin: 0 auto 1.5rem auto;  /* Center logo and add margin */
    width: 60px;  /* Slightly smaller logo */
    height: 60px;  /* Maintain aspect ratio */
  }

  .nav-links {
    flex-direction: column;  /* Stack nav items vertically on tablets */
    gap: 1rem;  /* Reduce gap for tablet size */
    width: 100%;  /* Make links take up full width */
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }

  .nav-link {
    font-size: 16px;  /* Slightly smaller font size */
    font-weight: 500;  /* Lighter font weight */
    padding: 0.5rem 0;  /* Add some padding for better touch interaction */
    text-align: center;
    transition: color 0.3s ease, transform 0.2s ease;
  }

  .nav-link:hover {
    color: #ffcc00;
    transform: scale(1.05);  /* Subtle scale effect for better touch interaction */
  }

  .nav-link.active {
    font-weight: 700;  /* Bold active link */
    color: yellow;
  }

  .contact-button {
    width: auto;  /* Responsive width for the contact button */
    margin-top: 1rem;  /* Space above button */
    padding: 0.5rem 1rem;
    font-size: 16px;  /* Adjust font size for touch devices */
    border-radius: 16px;
  }

  .contact-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(255, 204, 0, 0.6);
  }
}
/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 195px;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  transition: all 0.3s ease;
  z-index: 1000;
}

.sidebar.open {
  left: 0;
}

.sidebar .nav-link {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  margin: 1rem 0;
  transition: color 0.3s ease;
}

.sidebar .nav-link:hover {
  color: #ffcc00;
}

.sidebar .close-button {
  align-self: flex-end;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 2rem;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  position: absolute;
  top: 0rem;
  left: -11rem;
  /* z-index: 1100; */
  cursor: pointer;
}

.hamburger .bar {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
  transition: all 0.3s ease;
}

/* Default: Hide the contact button in the navbar */
.contact-button.desktop-only {
  padding: 1.5rem 3rem; /* Larger padding for desktop-only button */
  font-size: 26px; /* Slightly larger font size for desktop */
}

/* Show the contact button in the sidebar */
.contact-button.mobile-only {
  display: none;
}

/* Hide the sidebar logo by default */



/* Mobile View: Adjust visibility */
@media (max-width: 768px) {
  /* Hide the navbar logo */
  .card .logo {
      display: none;
  }

  .card{
    display: none;
  }

  .hamburger {
    top: 0rem;
    left: -11rem;
  }

  /* Show the sidebar logo */
  .sidebar .logo {
    display: block;
    width: 70px;
    height: 70px;
    background: url('logo.png') no-repeat center center;
    background-size: cover;
    border-radius: 50%;
    margin-right: 0rem;
    margin-left: -8rem;
  }
  
}

/* Tablet and desktop view: Show the navbar's contact button */
@media (min-width: 769px) {
  .contact-button.desktop-only {
    display: block;
  }
}

/* Mobile view: Show the sidebar's contact button */
@media (max-width: 768px) {
  .contact-button.mobile-only {
    display: block;
  }
}



/* Mobile View */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .card {
    position: relative;
  }
}

/* Tablet Landscape and iPad Styles (Between 768px and 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .card {
    flex-direction: row;  /* Layout elements in a row */
    justify-content: space-between;  /* Space elements evenly */
    padding: 0 1.5rem;
    height: auto;  /* Allow dynamic height */
  }

  .logo {
    margin: 0 1rem 0 0;  /* Add margin for alignment */
  }

  .nav-links {
    flex-direction: row;  /* Keep nav items in a row for tablets */
    gap: 1.5rem;  /* Appropriate gap between links */
    align-items: center;
  }

  .nav-link {
    font-size: 18px;  /* Set a comfortable font size */
    font-weight: 500;  /* Regular weight for readability */
  }

  .contact-button {
    margin-top: 0;  /* Remove top margin for better alignment */
    padding: 0.5rem 1.5rem;
  }

  .nav-link:hover {
    transform: scale(1.05);
    color: #ffcc00;
  }

  .contact-button:hover {
    box-shadow: 0 4px 12px rgba(255, 204, 0, 0.6);
  }
}

/* Desktop Styles */
@media (min-width: 1025px) {
  .card {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2rem;
  }

  .nav-links {
    gap: 2rem;
  }

  .nav-link {
    font-size: 20px;
  }

  .contact-button {
    padding: 0.5rem 2rem;
  }
}

.header-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-button.desktop-only {
  position: absolute;
  top: 50%;
  right: -130px; /* Adjust for horizontal spacing */
  transform: translateY(-50%);
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  display: none; /* Hidden by default */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-button:hover {
  /* transform: translateY(0px); */
  box-shadow: 0 4px 12px rgba(255, 204, 0, 0.6);
}

.contact-button.mobile-only {
  display: block;
}

@media (min-width: 1024px) {
  .contact-button.desktop-only {
    display: block; /* Show for desktop */
  }

  .contact-button.mobile-only {
    display: none; /* Hide for desktop */
  }
}