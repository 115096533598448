
  
  /* Container for the map */
  .map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Full height to center the map vertically */
    padding: 20px;
    overflow-x: hidden;
    width:100%
  }
  
  /* Responsive map image */
  .responsive-map {
    width: 100%; /* Takes full width of the container */
    max-width: 1280px; /* Limit the size for larger screens */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: Rounded corners *//* Optional: Subtle shadow */
    margin-left: -38px;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .map-container {
      padding: 10px;
    }
  
    .responsive-map {
      max-width: 100%; /* Map fits smaller screen widths */
      margin-left: 0;
    }
  }
  
  @media (max-width: 480px) {
    .map-container {
      padding: 5px;
    }
  
    .responsive-map {
      max-width: 100%; /* Adjust for extra small screens */
    }
  }
  