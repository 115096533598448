.trusted-section {
  background-color: white;
  padding: 40px 1px;
  width: 100%;
  text-align: center;
}

/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  color: #4a4a4a;
}

.line-left,
.line-right {
  position: relative;
  width: 241px;
  height: 17px;
}

.line-right {
  transform: scaleX(-1);
}

.line {
  position: absolute;
  width: 210px;
  height: 0;
  border: 2px solid #000;
  top: 8px;
}

.circle {
  position: absolute;
  width: 17px;
  height: 17px;
  border: 2px solid #100C08;
  border-radius: 50%;
  top: 0;
  right: 0;
}

/* Logos Container */
.logos-container {
  overflow: hidden;
  margin-top: 40px;
  position: relative;
  width: 100%;
}

.logos-track {
  display: flex;
  gap: 10px;
  animation: scroll-loop 5s linear infinite;
; /* Ensure it spans twice the width */
}

.logo-item {
  width: 150px;
  height: 100px;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-item img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* Infinite Scrolling Animation */
@keyframes scroll-loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-8%);
  }
}

/* Responsive Design */

/* Tablet Screens */
@media (max-width: 768px) {
  .header {
    gap: 5px;
  }

  .title {
    font-size: 1.5rem;
  }

  .line-left,
  .line-right {
    width: 200px;
    height: 15px;
  }

  .line {
    width: 180px;
    top: 6px;
  }

  .circle {
    width: 12px;
    height: 12px;
  }

  .logos-container {
    padding: 0 20px;
  }

  .logo-item {
    width: 120px;
    height: 80px;
    padding: 8px;
  }
}

/* Mobile Screens */
@media (max-width: 480px) {
  .header {
    flex-direction: column;
    gap: 5px;
  }

  .title {
    font-size: 1.25rem;
    text-align: center;
  }

  .line-left,
  .line-right {
    width: 150px;
    height: 12px;
  }

  .line {
    width: 126px;
    top: 5px;
  }

  .circle {
    width: 10px;
    height: 10px;
  }

  .logos-container {
    padding: 0 10px;
  }

  .logo-item {
    width: 100px;
    height: 70px;
    padding: 5px;
  }
}